import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';

/**
 * @function useCosting
 * @description a custom hook which handles APIs related to costing
 * @returns {object} object
 */
const useCosting = () => {
  const { user } = useSelector(selectAuth);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientRowData, setClientRowData] = useState({});
  const [clientData, setClientData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [clientCostingData, setClientCostingData] = useState({});
  const [clientPermission, setClientPermission] = useState({});

  /**
   * @function getSearchDetails
   * @description A function to get search details
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const getSearchDetails = (data) => {
    setIsLoading(true);
    return axios
      .get(`${API_ENDPOINTS.ADMIN_DASHBOARD_SEARCH_HISTORY}${data}/`)
      .then(
        (response) => {
          setIsLoading(false);
          setSearchData(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  const fetchClientData = (data) => {
    const queryParam = {
      dbname_list: data?.dbname,
      screen_type:
        data?.screen_type?.length === 2
          ? 'i,o'
          : data?.screen_type?.includes('Initial Screening')
          ? 'i'
          : data?.screen_type?.includes('Ongoing Monitoring')
          ? 'o'
          : null,
      start_date: data?.start_date,
      end_date: data?.end_date,
      categories: data?.categories
        ?.map((category) =>
          category.toLowerCase() === 'risk rating'
            ? 'risk_rating'
            : category.toLowerCase()
        )
        ?.join(','),
      breakdown: data?.breakdown,
    };
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.CLIENT_COSTING, { params: queryParam }).then(
      (response) => {
        setIsLoading(false);
        setClientCostingData(response?.data);
        return response;
      },
      () => {
        setIsLoading(false);
      }
    );
  };
  const fetchClientPermission = (data) => {
    const queryParam = { ...data };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.CLIENT_PERMISSION, {
        params: queryParam,
        loader: false,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setClientPermission(response?.data);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  return {
    user,
    isError,
    isLoading,
    clientData,
    clientRowData,
    setClientRowData,
    getSearchDetails,
    searchData,
    fetchClientData,
    clientCostingData,
    fetchClientPermission,
    clientPermission,
  };
};

export default useCosting;
