// @ts-nocheck
import * as _ from 'lodash';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Keycloak from 'keycloak-js';
import axios from 'axios';
import { DOWNLOADS_TABLE_HEADERS } from '../const/TableHeaders';
// eslint-disable-next-line no-unused-vars
import banklogo from '../assets/images/logo.png';
import solytics from '../assets/images/logo2.png';

export const pdfDownloadHandler = (name, div, user, organisation) => {
  const input = document.getElementById(div);
  const clientHeight = input.clientHeight + 50;
  const { clientWidth } = input;
  // Logging intentionally for debugging
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'px', [clientWidth, clientHeight]);
    const imgProps = pdf.getImageProperties(imgData);
    const width = pdf.internal.pageSize.getWidth();
    const height = (imgProps.height * width) / imgProps.width;

    function loadImage(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.src = url;
      });
    }

    pdf.addImage(imgData, 'PNG', 0, 40, width, height);
    pdf.text(
      `Reviewed By Analyst : ${user} on ${new Date().toLocaleString()}`,
      width - 350,
      35
    );
    pdf.setFontSize(35);
    pdf.text(`${organisation.name}`, 15, 35);
    pdf.setFontSize(16);
    pdf.text('Powered By ', width - 65 - width / 2, height + 30);
    loadImage(solytics).then((logo1) => {
      pdf.addImage(logo1, 'png', width - width / 2, height + 15, 90, 25);
      pdf.save(`${name}.pdf`);
    }); // Format is like this => X-Y-width-height for image only

    // loadImage(banklogo).then((logo) => {
    //   pdf.addImage(logo, 'png', 15, 15, width * 0.2, width * 0.05);
    // });
  });
};

const getJsonDownloadUrl = (jsonData) => {
  const output = JSON.stringify({ jsonData }, null, 4);
  const blob = new Blob([output]);
  const downloadUrl = URL.createObjectURL(blob);
  return downloadUrl;
};

const getDataFromLocalStorage = (key) => {
  let dataFromLocalStorage = {};
  try {
    dataFromLocalStorage = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : {};
  } catch (e) {
    return dataFromLocalStorage;
  }
  return dataFromLocalStorage;
};

const downloadFile = (link, fileName) => {
  const dlink = document.createElement('a');
  dlink.download = fileName;
  dlink.href = link;
  dlink.onclick = function () {
    // revokeObjectURL needs a delay to work properly
    const that = this;
    setTimeout(function () {
      window.URL.revokeObjectURL(that.href);
    }, 1500);
  };

  dlink.click();
  dlink.remove();
};

const summaryTableDataMapper = (searchType, tableData) => {
  const tableIndividual = tableData.map((item) => {
    return {
      'Header Name': 'Personal Information',
      'Full Name': item.name,
      Alias: Array.isArray(item.alias_name)
        ? `${item.alias_name.join().slice(0, 150)}...`
        : 'N/A',
      Address: item.matched_address,
      Gender: item.gender,
    };
  });
  return tableIndividual;
};
// converting hierarchical structure to flat
const prepareCsvDataOfDownloads = (downloads, type) => {
  let dataToReturn = [];
  if (downloads?.length) {
    let rootLevelColumns = DOWNLOADS_TABLE_HEADERS.ROOT_LEVEL;
    switch (type) {
      case 'individual':
        rootLevelColumns = [
          ...rootLevelColumns,
          ...DOWNLOADS_TABLE_HEADERS.INDIVIDUAL_ADDITIONAL_INFO,
        ];
        break;
      case 'entity':
        rootLevelColumns = [
          ...rootLevelColumns,
          ...DOWNLOADS_TABLE_HEADERS.ENTITY_ADDITIONAL_INFO,
        ];
        break;
      case 'vessel':
        rootLevelColumns = [
          ...rootLevelColumns,
          ...DOWNLOADS_TABLE_HEADERS.VESSEL_ADDITIONAL_INFO,
        ];
        break;
      case 'aircraft':
        rootLevelColumns = [
          ...rootLevelColumns,
          ...DOWNLOADS_TABLE_HEADERS.AIRCRAFT_ADDITIONAL_INFO,
        ];
        break;
      default:
    }
    // eslint-disable-next-line no-use-before-define
    const columnNames = createColumnNamesRow(
      rootLevelColumns,
      DOWNLOADS_TABLE_HEADERS.SL,
      DOWNLOADS_TABLE_HEADERS.AMS,
      DOWNLOADS_TABLE_HEADERS.PEP,
      DOWNLOADS_TABLE_HEADERS.LEGAL,
      DOWNLOADS_TABLE_HEADERS.CORPORATE_DETAILS,
      DOWNLOADS_TABLE_HEADERS.CHARGES,
      DOWNLOADS_TABLE_HEADERS.SIGNATORIES,
      downloads
    );
    if (columnNames?.length) {
      dataToReturn.push(columnNames);
    }

    downloads?.forEach((row) => {
      const maxSize = _.max([
        row?.AMS?.length,
        row?.PEP?.length,
        row?.Sanctions?.length,
        row?.Legal?.length,
        row?.Corporate?.masterData?.length,
        row?.Corporate?.charges?.length,
        row?.Corporate?.directors_history?.length,
      ]);
      dataToReturn = [
        ...dataToReturn,
        // eslint-disable-next-line no-use-before-define
        ...createRowData(
          row,
          rootLevelColumns,
          maxSize || 1,
          DOWNLOADS_TABLE_HEADERS.SL,
          DOWNLOADS_TABLE_HEADERS.AMS,
          DOWNLOADS_TABLE_HEADERS.PEP,
          DOWNLOADS_TABLE_HEADERS.LEGAL,
          DOWNLOADS_TABLE_HEADERS.CORPORATE_DETAILS,
          DOWNLOADS_TABLE_HEADERS.CHARGES,
          DOWNLOADS_TABLE_HEADERS.SIGNATORIES
        ),
      ];
    });
  }

  return dataToReturn;
};

const autoScroll = (id) => {
  setTimeout(function () {
    const element = document.getElementById(id);
    window.scrollTo({
      behavior: 'smooth',
      top: element
        ? element.offsetTop
        : document.body.scrollHeight || document.documentElement.scrollHeight,
    });
  }, 200);
};
const autoScrollToComponent = (myComponentRef) => {
  if (myComponentRef.current) {
    setTimeout(() => {
      myComponentRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }, 50);
  }
};

const createRowData = (
  row,
  columns,
  iterationLength,
  slColumns,
  amsColumns,
  pepColumns,
  LegalColumns,
  CorporateColumns,
  ChargesColumns,
  DirectorColumns
) => {
  const arr = [];

  for (let i = 0; i < iterationLength; i = +1) {
    const newRow = [];
    // level zero
    columns.forEach((col) => {
      newRow.push(col.dataExtractor(row));
    });

    // sl
    if (slColumns?.length && row?.Sanctions?.length)
      slColumns.forEach((col) => {
        newRow.push(
          row.Sanctions?.[i] ? col.dataExtractor(row.Sanctions[i]) : ''
        );
      });

    // ams
    if (amsColumns?.length && row?.AMS?.length)
      amsColumns.forEach((col) => {
        newRow.push(row.AMS?.[i] ? col.dataExtractor(row.AMS[i]) : '');
      });

    // pep
    if (pepColumns?.length && row?.PEP?.length)
      pepColumns.forEach((col) => {
        newRow.push(row.PEP?.[i] ? col.dataExtractor(row.PEP[i]) : '');
      });

    // Legal
    if (LegalColumns?.length && row?.Legal?.length)
      LegalColumns.forEach((col) => {
        newRow.push(row.Legal?.[i] ? col.dataExtractor(row.Legal[i]) : '');
      });

    // Corprate MasterData
    if (CorporateColumns?.length && row?.Corporate?.masterData?.length)
      CorporateColumns.forEach((col) => {
        newRow.push(
          row?.Corporate?.masterData?.[i]
            ? col.dataExtractor(row?.Corporate?.masterData[i])
            : ''
        );
      });
    // Corprate Charges
    if (ChargesColumns?.length && row?.Corporate?.charges?.length)
      ChargesColumns.forEach((col) => {
        newRow.push(
          row?.Corporate?.charges?.[i]
            ? col.dataExtractor(row?.Corporate?.charges[i])
            : ''
        );
      });
    // Corprate Director
    if (DirectorColumns?.length && row?.Corporate?.directors_history?.length)
      DirectorColumns.forEach((col) => {
        newRow.push(
          row?.Corporate?.directors_history?.[i]
            ? col.dataExtractor(row?.Corporate?.directors_history[i])
            : ''
        );
      });

    arr.push(newRow);
  }

  return arr;
};

const createColumnNamesRow = (
  rootLevelColumns,
  SL,
  AMS,
  PEP,
  LEGAL,
  CORPORATE_DETAILS,
  CHARGES,
  SIGNATORIES,
  downloads
) => {
  const columnsRow = [];
  let containsSl = false;
  let containsAms = false;
  let containsPep = false;
  let containsLegal = false;
  let containsCorporate = false;
  let containsCharges = false;
  let containsDirector = false;

  rootLevelColumns.forEach((col) => {
    columnsRow.push(col.label);
  });

  // checking if any of row contain SL, AMS, PEP, Legal, Corporate
  downloads?.forEach((row) => {
    if (!containsSl) {
      containsSl = Boolean(row?.Sanctions?.length);
    }
    if (!containsAms) {
      containsAms = Boolean(row?.AMS?.length);
    }
    if (!containsPep) {
      containsPep = Boolean(row?.PEP?.length);
    }
    if (!containsLegal) {
      containsLegal = Boolean(row?.Legal?.length);
    }
    if (!containsCorporate) {
      containsCorporate = Boolean(row?.Corporate?.masterData?.length);
    }
    if (!containsCharges) {
      containsCharges = Boolean(row?.Corporate?.charges?.length);
    }
    if (!containsDirector) {
      containsDirector = Boolean(row?.Corporate?.directors_history?.length);
    }
  });

  // if row contains  SL, AMS, PEP, Legal, Corporate then add entry to headings.
  // SL, AMS, PEP sequence matters
  if (containsSl) {
    SL.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsAms) {
    AMS.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsPep) {
    PEP.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsLegal) {
    LEGAL.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsCorporate) {
    CORPORATE_DETAILS.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsCharges) {
    CHARGES.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  if (containsDirector) {
    SIGNATORIES.forEach((col) => {
      columnsRow.push(col.label);
    });
  }
  return columnsRow;
};

const validateFileColumns = (fileColumns, columns) => {
  if (fileColumns.length !== columns.length) return false;
  for (let i = 0; i < columns.length; i += 1) {
    if (fileColumns[i] !== columns[i]) return false;
  }
  return true;
};
/**
 * @function validateInitiateFileColumns
 * @description a function to show validated initiated file columns
 * @param {array} fileColumns - file columns
 * @param {array} columns - uploaded file columns
 * @param {bool} permission - file permission
 * @returns {bool}
 */
const validateInitiateFileColumns = (fileColumns, columns) => {
  if (fileColumns.length !== columns.length) return false;
  for (let i = 0; i < columns.length; i += 1) {
    if (fileColumns[i] !== columns[i]) return false;
  }
  return true;
};
/**
 * @function alertListType
 * @description a function to show alert list type
 * @param {object} cardData
 * @returns {array}
 */
const alertListType = (cardData) => {
  const output = [];
  if (cardData.sanction_count || cardData.sanction_country_count)
    output.push('Sanctions');
  if (cardData.pep_count) output.push('PEP');
  if (cardData.ams_count) output.push('AMS');
  if (cardData?.risk_rating_alert) output.push('RISK');
  if (cardData.legal_count) output.push('Legal');
  if (cardData.corporate_count) output.push('Corporate');
  if (output.length === 0) return 'No Alert';
  return output.join(', ');
};

/**
 * @function alertBySanctions
 * @description shows the type of alert(Sanctions, PEP,AMS)
 * @param {object} cardData card containing data of searched user
 * @returns {array}
 */
const alertBySanctions = (cardData) => {
  const output = [];
  if (cardData?.sanction_alert) output.push('Sanctions');
  if (cardData?.pep_alert) output.push('PEP');
  if (cardData?.ams_alert) output.push('AMS');
  if (cardData?.risk_rating_alert) output.push('RISK');
  if (cardData?.legal_alert) output.push('Legal');
  if (cardData?.corporate_alert) output.push('Corporate');
  if (output.length === 0) return 'N/A ';
  return output.join(', ');
};
const initKeycloak = async () => {
  const initOptions = {
    url: process.env.REACT_APP_KEYCLOACK_URL,
    realm: localStorage.getItem('REALM_ID'),
    clientId: 'frontend',
    onLoad: 'check-sso', // check-sso | login-required
    KeycloakResponseType: 'code',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  };

  const keycloakConfig = new Keycloak(initOptions);
  window.KEYCLOAK_CONFIG = keycloakConfig;
  return keycloakConfig
    .init({
      onLoad: 'login-required',
      KeycloakResponseType: 'code',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false,
      pkceMethod: 'S256',
    })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        localStorage.setItem('auth', auth);
        document.dispatchEvent(
          new CustomEvent('KEYCLOAK_LOGIN_SUCCESS', {
            detail: { auth, keycloakConfig },
          })
        );
      }
      return auth;
    });
  // .catch((e) => {
  //   SwalToast({
  //     icon: 'error',
  //     title: e.error,
  //   });
  // });
};
const isValueEmpty = (value) => {
  switch (true) {
    case value === undefined:
    case value === null:
      return true;
    case typeof value === 'object':
      return !Object.keys(value).length;
    case typeof value === 'string':
      return !value.trim().length;
    default:
      return false;
  }
};
const getErrorHelperText = (error, touched) => {
  return touched ? error : null;
};
const customAxiosForFastApi = axios.create({
  baseURL: process.env.REACT_APP_FAST_API_BASE_URL, // Custom baseURL
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Add a request interceptor
customAxiosForFastApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const org = localStorage.getItem('org');

    if (token) {
      config.headers.HTTPBearer = `Bearer ${token}`;
    }
    if (org) {
      config.headers.org = org;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const CoreUtils = {
  isValueEmpty,
  initKeycloak,
  getErrorHelperText,
  pdfDownloadHandler,
  getJsonDownloadUrl,
  getDataFromLocalStorage,
  downloadFile,
  summaryTableDataMapper,
  prepareCsvDataOfDownloads,
  autoScroll,
  autoScrollToComponent,
  createRowData,
  createColumnNamesRow,
  validateFileColumns,
  alertListType,
  alertBySanctions,
  validateInitiateFileColumns,
  customAxiosForFastApi,
};
